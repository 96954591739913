import React, { createContext } from "react"
import { onIdTokenChanged } from ".."
import { PokeLoading } from "src/components/Loading"
import { useRouter } from "next/router"
import { User as FirebaseUser } from "firebase/auth"

type Props = {
  children?: React.ReactNode
}

export type Tenant = {
  id: string
  uid: string
  name: string | null
  email: string | null
  photoUrl: string | null
  emailVerified: boolean
  customClaims: CustomClaims
}

export type CustomClaims = { [key: string]: unknown }

type AuthState = {
  tenant: Tenant | null
  user: FirebaseUser | null
}

export const mapFirebaseResponseToTenant = (
  user: FirebaseUser & {
    customClaims: CustomClaims
  }
): Tenant => {
  const providerData = user.providerData && user.providerData[0]

  if (!user.isAnonymous && providerData) {
    return {
      id: user.uid,
      uid: user.uid,
      name: providerData.displayName || user.displayName || user.email || null,
      email: providerData.email || null,
      emailVerified: user.emailVerified || false,
      photoUrl: providerData.photoURL || null,
      customClaims: user.customClaims || {},
    }
  }

  return {
    id: user.uid,
    uid: user.uid,
    name: user.displayName || providerData?.displayName || user.email || null,
    email: user.email || null,
    emailVerified: user.emailVerified || false,
    photoUrl: user.photoURL || null,
    customClaims: user.customClaims || {},
  }
}

export const AuthContext = createContext<{
  auth: AuthState
  setAuth: React.Dispatch<React.SetStateAction<AuthState>>
}>(null)

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const [auth, setAuth] = React.useState<AuthState>({
    tenant: null,
    user: null,
  })

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

/**
 * @deprecated ややこしいので、src/features/auth/utils/auth-provider.tsxを使うようにする
 */
export const Auth: React.FC<{
  children?: React.ReactNode
}> = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>
}
