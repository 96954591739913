"use client"
// src/contexts/AppStateContext.tsx
import React, { Dispatch, SetStateAction, useContext, useState } from "react"
import { logger } from "src/lib/utils/logger"
import { ForCICMainExaminationScores } from "src/features/for-cic/components/ForCIC"

export type AppState = {
  auth: {
    sessionInitialized: boolean
  }
  forCIC: {
    mainExaminationScores: ForCICMainExaminationScores & {
      newly: ForCICMainExaminationScores["main_examination_scores"]
    }
  }
}

const initialState: AppState = {
  auth: {
    sessionInitialized: false,
  },
  forCIC: {
    mainExaminationScores: {
      moving_applications: null,
      main_examination_scores: null,
      newly: null,
      proposals: null,
      users: null,
      search_houses: null,
    },
  },
}

const AppStateContext = React.createContext<AppState>(initialState)
const SetAppStateContext = React.createContext<Dispatch<SetStateAction<AppState>>>(() => null)

export function useAppState() {
  return useContext(AppStateContext)
}
export function useSetAppState() {
  return useContext(SetAppStateContext)
}

export function AppStateProvider(props: { initialState?: AppState; children: React.ReactNode }) {
  const [state, defaultSetState] = useState<AppState>(props.initialState ?? initialState)

  const setState: typeof defaultSetState = (value) => {
    logger.log("setAppState", {
      value: value instanceof Function ? value(state) : JSON.stringify(value),
    })

    defaultSetState(value)
  }

  return (
    <AppStateContext.Provider value={state}>
      <SetAppStateContext.Provider value={setState}>{props.children}</SetAppStateContext.Provider>
    </AppStateContext.Provider>
  )
}
