import Swal from "sweetalert2"
import { renderToString } from "react-dom/server"
import React from "react"
import { css } from "styled-components"
import { color, fontFamily } from "@smoothincorg/smooth-ui"
import { Check } from "src/components/Icons/Check"

const PREFIX = "snack-bar-card"

export const snackBarCardStyles = css`
  .${PREFIX} {
    padding-top: 70px !important;
    padding-left: 0 !important;
    left: -10 !important;
    &-popup {
      width: 250px !important;
      padding: 40px 24px !important;
    }

    &__title {
      ${fontFamily.inter};
      font-style: normal;
      font-weight: bold;
      font-size: 14px !important;
      line-height: 20px;
      color: ${color.mediumGray}!important;
      margin: 0 !important;
    }

    &__icon {
      border-color: transparent !important;
      margin-right: 4px;
      * {
        fill: ${color.main};
        color: ${color.main};
      }
    }
  }
`

export const SnackBarCard = Swal.mixin({
  toast: true,
  position: "top-start",
  showConfirmButton: false,
  timer: 3000,
  icon: "success",
  customClass: {
    container: PREFIX,
    title: `${PREFIX}__title`,
    icon: `${PREFIX}__icon`,
    popup: `${PREFIX}-popup`,
  },
  iconHtml: renderToString(React.createElement(Check)),
})
