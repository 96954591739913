export const Warn = () => (
  <svg
    width={60}
    height={60}
    viewBox="0 0 114 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // {...props}
  >
    <circle cx={57} cy={57} r={51.3} fill="#E6C160" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.575 32.775a2.85 2.85 0 0 0-2.85 2.85v25.65a2.85 2.85 0 0 0 2.85 2.85h2.85a2.85 2.85 0 0 0 2.85-2.85v-25.65a2.85 2.85 0 0 0-2.85-2.85h-2.85Zm0 39.9a2.85 2.85 0 0 0-2.85 2.85v2.85a2.85 2.85 0 0 0 2.85 2.85h2.85a2.85 2.85 0 0 0 2.85-2.85v-2.85a2.85 2.85 0 0 0-2.85-2.85h-2.85Z"
      fill="#fff"
    />
  </svg>
)
