import { RecoilRoot } from "recoil"
import { AppProps } from "next/app"
import { createGlobalStyle } from "styled-components"
import { snackBarCardStyles } from "src/lib/ui/snack-bar-card"
import { modalStyles } from "src/lib/ui/modal"
import { SWRConfig } from "swr"
import { AuthProvider } from "src/features/auth"
import "src/lib/styles/global.scss"
import { AppStateProvider } from "src/store/AppStateContext"
import { useEffect } from "react"
import { styles as alertStyles } from "src/lib/modules/alert"
import { DATADOG_PARAMS } from "src/lib/constants/values"
import { datadogRum } from "@datadog/browser-rum"

const GlobalStyle = createGlobalStyle`${snackBarCardStyles}${modalStyles}${alertStyles}`

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    datadogRum.init(DATADOG_PARAMS);
  }, []);

  return (
    <AppStateProvider>
      <SWRConfig
        value={{
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        <AuthProvider>
          <RecoilRoot>
            <GlobalStyle />
            <Component {...pageProps} />
          </RecoilRoot>
        </AuthProvider>
      </SWRConfig>
    </AppStateProvider>
  )
}

export default App
