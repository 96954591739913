import * as React from "react"

export const Check = () => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.998 19.995a9.998 9.998 0 1 1 9.997-9.997 10.012 10.012 0 0 1-9.997 9.997Zm0-18.24a8.243 8.243 0 1 0 8.242 8.243 8.252 8.252 0 0 0-8.242-8.243Zm-5.369 8.568 1.159-1.311 3.376 2.957 4.93-5.831 1.326 1.119-6.068 7.177-4.723-4.111Z"
      fill="#7E9094"
    />
  </svg>
)
