import { color, fontFamily } from "@smoothincorg/smooth-ui"
import { css } from "styled-components"
import Swal from "sweetalert2"
// import { ReactComponent as error_icon } from 'src/assets/images/error.svg'
import React from "react"
import { renderToString } from "react-dom/server"
import { Warn } from "src/components/Icons/Warn"

export const modalStyles = css`
  .modal {
    .swal2-title {
      font-size: 1.4rem !important;
      line-height: 150%;
    }
    .swal2-icon,
    .swal2-loader {
      border-color: transparent !important;
      outline: none !important;
    }

    .alert {
      ${fontFamily.roboto}
      font-size: 1.6rem;
      font-weight: 600;
      &__content {
        display: block;
        font-size: 1.2rem;
      }
    }
    .swal2-confirm {
      ${fontFamily.roboto};
      font-weight: bold;
      font-size: 1.4rem !important;
      width: 100%;
      height: 48px;
      background: linear-gradient(90deg, #86d0e2 0%, #6ec3d8 100%) !important;
    }
    .swal2-cancel {
      ${fontFamily.roboto};
      font-weight: bold;
      font-size: 1.4rem !important;
      width: 100%;
      height: 48px;
      background: #ffffff !important;
      margin-top: 12px;
      border: 2px solid #e1e9eb !important;
      color: #000000 !important;
    }

    .swal2-popup {
      padding: 26px 10px;
      border-radius: 8px !important;
    }

    .swal2-image {
      margin: 0 auto 10px;
    }

    .swal2-actions {
      display: flex;
      height: 42px;

      & > button {
        display: block;
        height: 100%;
        margin: 0;
        box-shadow: none;
        border-radius: 6px;
        box-shadow: none !important;
        opacity: 1 !important;

        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .swal-loading-icon {
      display: block;
      width: 20px;
      margin: 20px auto;
    }

    &.swal-custom-confirm {
      .swal2-actions {
        width: 100%;
        margin-bottom: 0;

        > button {
          width: 46%;
        }
      }
    }

    &.confirm {
      button {
        width: 40% !important;
      }

      .swal2-confirm {
        width: 58% !important;
      }

      .swal2-actions {
        width: 80% !important;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

export const Alert = Swal.mixin({
  icon: "error",
  iconHtml: renderToString(React.createElement(Warn)),
  iconColor: color.main,
  confirmButtonColor: color.main,
  customClass: {
    container: "modal",
  },
})

export const Confirm = Swal.mixin({
  icon: "error",
  iconHtml: renderToString(React.createElement(Warn)),
  iconColor: color.main,
  confirmButtonColor: color.main,
  showCancelButton: true,
  customClass: {
    container: "modal confirm",
  },
})
